*, ::before, ::after {
  border-style: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400; //see index.html link for weight options
  font-style: normal;
}

$homeBackColor: hsl(24, 100%, 4%);
$projTileBack: hsla(24, 100%, 20%, 0.2);
$darkAccentColor: hsl(24, 100%, 25%);
$homeAccentColor: hsl(24, 70%, 50%);
$homeOffWhite: hsl(24, 100%, 95%);

html {
  background-color: $homeBackColor;
}

body {
  margin: 0;
  text-align: center;
  font-family: arial;
  border: 0px solid black;
  background-color: $homeBackColor;
}

#root {
  background-color: $homeBackColor;
}

//header nav bar styles
header {
  position: fixed;
  top: 0px;
  //height: 65px;
  width: 100%;
  //background-color: $homeAccentColor;
  display: flex;
  justify-content: center;
  //border: 1px solid pink;
  //opacity: 80%;
  z-index: 1;
  
  #navbar {
    width: 95%;
    margin: 15px 0 5px 0;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 25px;
    border: 1px solid rgba(0,0,0, 0);
    //border: 0px solid pink;
    border-radius: 35px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    //border-bottom: 1px solid $homeAccentColor;
    

    img {
      margin: -4px 50px 0 0;
      padding: 0 0 0px 0;
      border: 0px solid black;
      transition: transform 0.5s ease;
    }

    img:hover {
      transition: 0.5s;
      transform: rotate(15deg);
    }
  }

  ul {
    list-style: none;
    margin: 0 10px 0 0px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 0px solid black;
  }

  #left-ul li {
    margin: 0 20px 0 10px;
    padding: 1px 0px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0, 0);
    transition: 0.5s;
  }

  #left-ul li:hover {
    //border-top: 1px solid $homeBackColor;
    //margin-top: 1px;
    //box-sizing: border-box;
    border-bottom: 1px solid white;
    transition: 0.5s;
  }

  @media only screen and (max-width: 600px) {
    #nav-about {
      display: none;
    }

    #left-ul li {
      margin: 0 0 0 0px;
    }

    #navbar img {
      margin: -4px 0 0 0;
    }

  }
  
  @media (max-width: 450px) {
    #left-ul {
      display: none;
    }
  }

  #contact-a  {
    height: 45px;
    //border: 1px solid black;
    //transition: 0.1s;
    padding: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    border: 0px solid white;
    transition: 0.5s;
    position: relative;
    overflow: hidden;
  }

  #contact-a:hover {
    color:  $homeBackColor;
    transition: 0.5s;
    border: 0px solid white;
  }

  #contact-a::before {
    content: '';
    position: absolute;
    top: 0;
    left: -110%;
    width: 100%;
    height: 100%;
    background-color: white; /* New background color */
    color: $homeAccentColor;
    transition: left 0.5s ease;
    z-index: -1;
    border: 1px solid white;
    //border-radius: 40px;
  }

  #contact-a:hover::before {
    left: 0;
    border: 4px solid white;
  }

  a {
    border: 0px solid black;
    margin: 0;
    padding: 0px 0 0 0;
    text-decoration: none;
    color: white;
    font-size: 1.3em;
    
  }
}

//home page styles
.home-page-app{
  * {
    text-align: center;
  }

  #welcome-section {
    padding: 120px 20px 0px 20px;
    margin: 0px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly ;
    align-items: center;
    background: $homeAccentColor;

    * {
      border: 0px solid black;
    }

    #left-welcome {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin: 0 20px 0 0;
    }

    img {
      margin: 0px 0 10px 0;
      border-radius: 200px;
      max-width: 400px;
    }

    .home-name {
      text-align: left;
      font-size: 4.5em;
      margin: 0px 0 0 0;
      padding: 0px 0 10px 0;
      color: white;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
    }

    h2 {
      font-size: 2em;
      text-align: left;
      margin: 0 0 0 3px;
      padding: 0px 0 25px 0;
      color: white;
    }

    #welcome-buttons {
      border: 0px solid black;
      padding: 10px;
      margin: -5px 0 0 0px;
      display: flex;
      width: 100%;

      .welcome-button {
        text-decoration: none;
        font-size: 1.25em;
        color: white;
        border: 1px solid white;
        padding: 20px;
        margin: 0 0 0 0;
        border-radius: 3px;
        background-color: none;
        //height: 50px;
        display: inline-block;
        transition: color 0.5s;
        position: relative;
        overflow: hidden;
        z-index: 0;
        width: 148px;
      }

      #work-tog-butt {
        margin: 0 30px 0 -7px
      }
      

      .welcome-button::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: white; /* New background color */
        color: $homeAccentColor;
        transition: left 0.5s ease;
        z-index: -1;
      }
    
      .welcome-button:hover::before {
        left: 0;
      }

      .welcome-button:hover {
        color:  $homeBackColor;
        transition: 0.5s;
      }
    }
  }

  @media only screen and (max-width: 880px) {
    #welcome-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      #left-welcome {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      #welcome-buttons {
        justify-content: space-evenly;
        max-width: 400px;
      }

      #welcome-buttons #work-tog-butt {
        margin: 0 0px 0 0px
      }

      #welcome-img {
        margin: 10px 0 0 0;
      }

      .home-name {
        text-align: center;
      }

      h2 {
        text-align: center;
      }
    }

    @media (max-width: 500px) {
      #welcome-img img {
        height: 300px;
        width: 300px;
      }

    }

    @media (max-width: 400px) {
      #left-welcome .home-name {
        font-size: 4.0em;
      }

      #welcome-section #welcome-buttons {
        flex-direction: column;
        align-items: center;
        margin: -10px 0 0 0;
      }

      #welcome-section #welcome-buttons #work-tog-butt {
        margin: 0px 0px 20px 0px
      }
    }

  }

  #about-scroll {
    height: 80px;
    font-size: 4em;
    width: 100%;
    background-color: $homeAccentColor;
    margin: 0;
  }

  #about-section {
    color: white;
    background-color: $homeBackColor;
    padding: 10px 50px 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    #about-para {
      max-width: 1000px;

      h1 {
        text-align: center;
        margin: 0 0 10px 0;
      }
  
      p {
        text-align: left;
        margin: 0 0 10px 0;
        font-size: 1em;
        letter-spacing: 1px;
      }

      #projects-header {
        padding: 0px 0 0 0;
        margin: 40px 0 0 0;
        border: 0px solid red;
        width: 100%;
        text-align: left;
      }   
    }
    
  }

  #projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $homeBackColor;
    color: white;
    padding: 20px 40px;
    margin: 0;
    border: 0px solid red;
    width: 100%;
    
    h1 {
      text-align: center;
      width: 100%;
      border: 0px solid yellow;
      max-width: 1100px;
      padding: 10px;
    }

    .project-tiles {
      width: 100%;
      //max-width: 600px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .project-tile {
        max-width: 800px;
        background-color: $projTileBack;
        //height: 200px;
        width: 675px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 25px;
        margin: 0 5px 20px 5px;
        padding: 10px;
        border: 1px solid $homeAccentColor;
        
        .tile-img-and-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 500px;
          border: 0px solid yellow;
          //padding: 10px;

          img {
            //width: 300px;
            height: 200px;
            padding: auto;
            background-color: $homeAccentColor;
            border-radius: 15px;
            border: 0px solid $homeAccentColor;
          }  

          .img-title {
            display: none;
          }
        
          @media (max-width: 650px) {
            .img-title {
              display: inline-block;
              border: 0px solid red;
              margin: 0 0 -10px 0;
            }
          }

          @media (max-width: 400px) {
            /*.img-title {
              display: flex;
            }*/
          }
          
        }
        
        .tile-description {
          //width: 400px;
          border: 0px solid pink;
          //height: 100%;
          //padding: 10px;
          margin: 0 20px 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h2 {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    

    .hiding-text {
      color: $projTileBack;
      transition: color 0.5s;
    }

    .project-tiles a:first-of-type:hover{
      cursor: default;
    }

    .project-tiles a:first-of-type:hover .hiding-text {
      color: $homeAccentColor;
    }
    
    #twentyFive:hover .hiding-text {
      color: $homeAccentColor;
    }

    #calculator:hover .hiding-text {
      color: $homeAccentColor;
    }

    #treemap:hover .hiding-text {
      color: $homeAccentColor;
    }

    #exercise:hover .hiding-text {
      color: $homeAccentColor;
    }

    #numguess:hover .hiding-text {
      color: $homeAccentColor;
    }

    #markdown:hover .hiding-text {
      color: $homeAccentColor;
    }

    #quotegenerator:hover .hiding-text {
      color: $homeAccentColor;
    }

    #drum:hover .hiding-text {
      color: $homeAccentColor;
    }

    #bargraph:hover .hiding-text {
      color: $homeAccentColor;
    }

    #scatterplot:hover .hiding-text {
      color: $homeAccentColor;
    }

    #heatmap:hover .hiding-text {
      color: $homeAccentColor;
    }

    #choropleth:hover .hiding-text {
      color: $homeAccentColor;
    }

    #earlywork:hover .hiding-text {
      color: $homeAccentColor;
    }

    .project-tiles h2 {
      font-size: 1.2rem;
      font-weight: normal;
      padding: 15px 0;
      width: 100%;
      text-decoration: none;
    }

    .project-tiles a {
      color: white;
      text-decoration: none;
    }

    #show-all {
      //background-color: grey;
      border: 1px solid white;
      margin: 40px;
      font-size: 1.2rem;
      padding: 10px 15px;
      border-radius: 2px;
      display: inline-block;
      transition: color 0.5s;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }

    #show-all:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: white; /* New background color */
      color: $homeAccentColor;
      transition: left 0.5s ease;
      z-index: -1;
    }
  
    #show-all:hover::before {
      left: 0;
    }

    #show-all:hover {
      color:  $homeBackColor;
      transition: 0.5s;
      cursor: pointer;
    }
  
    /*
    @media (max-width: 1500px) {
      .project-tiles .project-tile {
        width: 600px;
      }
    }*/

    @media (max-width: 1500px) {
      .project-tiles .project-tile {
        width: auto;
        max-width: 800px;
      }
    }

    @media (max-width: 950px) {
      .project-tiles .project-tile .tile-description {
        width: auto;
        //color: red;
      }
      
      .project-tiles .project-tile {
        width: 100%;
      }

    }

    @media (max-width: 720px) {
      .project-tiles .project-tile .tile-description .description-title {
        font-size: 1.0em;
      }

      .project-tiles .project-tile .tile-description p {
        font-size: 0.9em;
      }
    }

    @media (max-width: 650px) {
      .project-tiles .project-tile .tile-description {
        display: none;
        //color: red;
      }

      .project-tiles .project-tile {
        justify-content: center;
        width: auto;
      }

      .project-tiles .project-tile {
        .tile-img-and-title {
          width: 300px;
        }
      } 

    }

    @media (max-width: 400px) {
      .project-tiles .project-tile .tile-img-and-title img {
        height: 175px;
      }

      .project-tiles .project-tile .tile-img-and-title {
        width: 250px;
      }

      

      .project-tiles .project-tile .tile-img-and-title .img-title {
        font-size: 1.0em;
      }
    }
  }

  #contact {
    display: flex;
    flex-direction: column;
    background-color: $homeBackColor;
    color: white;
    padding: 0px 0 50px 0;
  

    h1 {
      font-size: 3rem;
      padding: 0px 10px;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0 20px;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 1.25rem;
      margin: 10px;
      display: flex;
      
    }

    a:hover {
      cursor: pointer;
      color: $homeAccentColor;
    }

    #phone:hover {
      cursor: default;
      color: white;
    }

    li {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      transition: transform 0.5s ease-out;
      list-style: none;

      svg {
        height: 25px;
        width: 25px;
      }

      

      

    }

    #contact-links span {
      font-size: 1.0em;
      padding: 2px 0 0 0;
    }

    #email span {
      padding: 1px 0 0 0;
    }

    li:hover {
      animation: lower 0.25s ease-in-out; 
    }

    
    

    @keyframes lower {
      0% {
        transform: translateY(0px)
      }

      50% {
        transform: translateY(2px)
      }

      100% {
        transform: translateY(0px);
      }
    }
  }
}

//twentyfive plus five styles
#twentyFiveContainer {
  
  height: 100%;//go to 100vh once we aren't tracking console
  background-color: $homeBackColor;
  color: white;
  display: flex;
  justify-content: center;
  padding: 110px 0 0 0;
  //align-items: center;

  * {
    border: 0px solid black;
    margin: 0;
    padding: 5px;
  }

  #timerContainer {
    width: 400px;
    padding: 0px;
    border: 0px solid black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $darkAccentColor;

    #timerTitle {
      border: 0;
      text-align: center;
    }

    #timerControls {
      border: 0px;
      width: 100%;

      .controlBox {
        border: 0;

        .controlTitle {
          border: 0;
          margin: -5px 0;
          font-size: 1.0em;
          padding: 0;
          text-align: center;
        }

        .controls {
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            border: 0;
            padding: 0;
            width: 25px;
            text-align: center;
            font-size: 1.3em;
          }
        }
        
        .controls:hover {
          cursor: pointer;

          p:hover {
            cursor: default;
          }
        }

        
      }
    }
    
    #timerBox {
      border: 5px solid $homeAccentColor;
      padding: 0;
      border-radius: 35px;
      height: 120px;
      width: 200px;

      h4 {
        text-align: center;
        border: 0;
        padding: 15px 0 0 0;
        
      }

      #timerDisplay {
        border: 0;
        text-align: center;
        font-size: 3em;
        padding: 0;
        margin: -10px 0;
      }
    }

    #playPauseReset {
      border: 0;
      padding: 0;
      margin-top: 10px;
      display: flex;
      

      div:hover {
        cursor: pointer;
      }
      
      #playPause {
        padding: 0;
        border: 0;

        * {
          border: 0;
          font-size: 1.2em;
        }
      }
      
      #reset {
        padding: 0 0 0 5px;
        border: 0;

        * {
          border: 0;
          font-size: 1.2em;
        }
      }
    }
    
  }

  @media (max-width: 450px) {
    #timerContainer {
      width: 350px;
    }
  }

  @media(min-height: 700px) {
    padding: 170px 0 0 0;
  }
  
}

//calculator styles
#calcContainer {
  $buttonHeight: 40px;
  //border: 0px solid black;
  background-color: $homeBackColor;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start; //production - go to center for centered app

  * {
    border: 0px solid black;
    margin: 5px;
  }

  #calculator {
    margin: 170px 0 0 0; //production - go to 0 for centered app
    //height: 400px;
    width: 320px;
    padding: 5px;
    border: 0px solid grey;
    background-color: $darkAccentColor;
    border-radius: 5px;
    //color: white;
    
    #display {
      padding: 0px;
      text-align: right;

      #equationDisp {
        color: orange;
        //border: 1px solid white;
        padding: 0px;
        margin: -3px 0;
        min-height: 22px;
        font-size: 0.9em;
      }

      #inputDisp {
        color: white;
        margin-top: 0px;
        padding: 0px;
        font-size: 1.1em;
        height: 25px;
        //border: 1px solid white;
      }
    }

    #allButtons {
      padding: 0px;
      margin-top: 2px;
      //height: 100vh;

      .row {
        padding: 0;
        border: 0;
        margin: 0;
        //width: 300px;
        height: 70px;
      }

      .tallRow {
        height: 140px;
        /*
        button {
          width: 77px;
        }

        #zero {
          width: 154px;
        }*/
      }
      
      button {
        background-color: hsl(0, 0%, 30%);
        color: white;
        margin: 0;
        //width: 70px;
        outline: 1px solid black;
        
        
      }

      button:hover {
        outline: 0.05em solid grey;
        color: black;
        z-index: 1;
      }

      #divide, #multiply, #subtract, #add {
        background-color: hsl(0, 0%, 40%);
      }

      #clear {
        background-color: $homeAccentColor;
        //width: 140px;
      }
      
      #equals {
        background-color: $homeAccentColor;
        //height: 100%;
      }
    }
  }

  @media (max-height: 800px) {
    #calculator {
      margin: 100px 0 0 0;
    }
  }
  
}

//treemap styles
#treeMapContainer {
  padding: 95px 0 15px 0;
  background-color: $homeBackColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  #treemap-app {
    padding: 0 50px 15px 50px;
    background-color: $darkAccentColor;
    max-width: 90%;
    max-height: 90%;
    border-radius: 20px;
    //overflow: scroll;
  }

  #vgTreeContainer {
    background-color: $darkAccentColor;

    h1 {
      margin: 0;
    }

    p {
      padding: 10px 0 15px 0;
    }

  }

  #mvTreeContainer {
    background-color: $darkAccentColor;

    h1 {
      margin: 0;
    }

    p {
      padding: 10px 0 15px 0;
    }
  }

  #ksTreeContainer {
    background-color: $darkAccentColor;
    //width: 900px;
    //overflow: auto;

    h1 {
      margin: 0;
    }

    p {
      padding: 10px 0 15px 0;
    }
  }

  * {
    text-align: center;
    color: white;
  }
  
  .link-text {
    text-decoration: underline;
    color: white;//$homeAccentColor;
    cursor: pointer;
  }

  p {
    padding: 10px 0 5px 0;
    background-color: $darkAccentColor;
    margin: 0;
    //width: 900px;
  }

  #treeMap {
    overflow: auto;
  }

  svg {
    border: 0px solid black;
    margin: 0px 0 0px 0;
    padding: 0 0 10px 0;
    //max-width: 900px;
    //max-height: 300px;
    overflow: scroll;
    //overflow-y: scroll;
  }
  
  #tooltip {
    padding: 10px;
    background-color: #ffffb3;
    color: black;
    text-align: center;
    position: absolute;
    left: -100px;
    top: 0px;
    visibility: hidden;
    border-radius: 2px;
    opacity: 0.95;
    font-size: 0.8em;
    word-wrap: break-word;
  }

  @media (max-width: 600px) {
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .hide-narrow {
      display: none;
    }
  }

  @media (min-height: 800px) {
    padding: 100px 0 0 0;
  }

  @media (min-height: 900px) {
    padding: 130px 0 0 0;
  }
}

//exercise-tracker styles
#exerTrackContainer {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $homeBackColor;
  margin: 0;
  
  * {
    border: 0px solid black;
    padding: 0px;
    margin: 0px;
    //color: white;
  }

  /*
  #trackerContainer {
    //max-width: 750px;
  }
  
  #formsContainer {
    display: flex;
  
  }
  */
  h1 {
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    color: white;
  }

  #formsContainer {
    margin-bottom: 15px;
    display: flex;
    //padding: 0 10px 0 10px;
  }
  
  .formCol {
    padding: 0 10px 0 10px;
    width: 425px;
    //height: 350px;
  }

  p {
    //max-width: 900px;
    text-align: center;
    color: white;
  }

  #exercise-form {
    height: 100%;
  }
  
  form {
    padding: 15px;
    background-color: $darkAccentColor;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      text-align: center;
      margin: 0 0 10px 0;
      color: white;
      width: 100%;
    }

    p {
      margin: 0 0 10px 0;
    }

    #required-note {
      width: 100%;
      text-align: left;
      margin: 0px 0 10px 0;
      color: rgb(194, 194, 194);
    }
  }

  #exLogForm {
    margin: 20px 0 0 0;
  }
  
  .userLogQuery {
    margin: 20px 0 0px 0;
    padding: 15px;
    background-color: $darkAccentColor;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0 0 10px 0;
    }

    button {
      font-size: 16px;
      border-radius: 3px;
      background-color: $homeAccentColor;
      border: 1px solid grey;
      box-shadow: 2px 2px #999;
      cursor: pointer;
      width: 100%;
      height: 35px;
    }
    /*
    button:hover {
      background-color: #FFFEC4;
      transition-duration: 0.5s;
    }*/
  }

  #userLogHead {
    text-align: center;
  }

  input {
    //display: block;
    margin: 0 0 10px 0;
    padding: 5px;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 3px;
    font-size: 16px;
    transition: 0.5s;
  }
  
  input[type=submit] {
    font-size: 16px;
    border-radius: 3px;
    background-color: $darkAccentColor;
    border: 1px solid $homeAccentColor;
    box-shadow: 2px 2px $homeAccentColor;
    cursor: pointer;
    color: white;
  }
  
  input[type=submit]:hover {
    color: black;
    background-color: white;
    transition: 0.5s;
  }

  #url-example {
    display: flex;
    justify-content: center;
    //max-width: 90%;
  }
  
  code {
    font-family: monospace;
    font-size: 1.2em;
    background-color: #FFFEC4;
    padding: 2px;
    color: red;
  }

  #get-log-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 10px 0;
    

    p {
      padding: 0 10px 0 10px;
      color: white;
    }
  }

  #server-resp-div {
    margin: 0 10px 10px 10px;
    background-color: #b9e6b3;
    padding: 1px 0 5px 0;
    border-radius: 5px;
    display: none;

    h2 {
      padding: 0 0 0 10px;
    }

    p {
      text-align: left;
      padding: 0 0 0 12px;
    }
  }

  #temp-stuff {
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;

    * {
      text-align: center;
      border: 1px solid black;
    }

    h2 {
      width: 100%;
      font-size: 1.5em;
    }

    
    button {
      border: 1px solid black;
      margin: 0 0 0 10px;
      width: 120px;
    }
  }

  @media (max-width: 900px) {
    #formsContainer {
      //margin-bottom: 15px;
      //display: flex;
      //padding: 0 10px 0 10px;
      flex-direction: column;
      align-items: center;
    }

    .formCol {
      margin: 0 0 20px 0;
    }

    #partial-ex-log-text {
      margin: -20px 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #url-example {
      flex-direction: column;
      width: 100%;
      align-items: center;
      
      code {
        margin: 5px 0 0 0;
        width: 300px;
      }
    }
  }

  @media (max-width: 750px) {
    #get-log-legend {
      display: flex;
      flex-direction: column;
      margin: 0px 0 0 0;

      p {
        margin: 5px 0 0 0;
      }
    }
  }

  @media (max-width: 500px) {
    .formCol {
      width: 300px;
    }
  }
}

//NodeResponse styles
#nodeRespContainer {
  margin-top: 70px;
}

//number guesser app styles
#num-guess-container {
  //background-color: red;
  display: flex;
  justify-content: center;
  background-color: $homeBackColor;
  height: 100vh;

  * {
    //border: 1px solid black;
    padding: 0px;
    margin: 10px;
    
  }
  
  #num-guess-forms {
    margin: 180px 0 0 0;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    border-radius: 0px;

    div {
      background-color: $darkAccentColor;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: left;
    }

    #emptyDiv {
      visibility: hidden;
    }

    h2 {
      margin: 0 10px 10px 10px;
      width: auto;
      color: white;
    }

    #guess-entry-head {
      //padding: 0 10px;
      font-size: 1.5em;
    }

    p {
      margin: 0 10px 0 10px;
      width: auto;
      font-size: 1.2em;
      color: white;
    }

    input {
      width: auto;
      
    }

    button {
      color: black;
      padding: 0 10px;
      background-color: white;
    }
  }

  @media (max-height: 750px) {
    #num-guess-forms {
      margin: 130px 0 0 0;
    }
  }
}

//markdown previewer app styles
#markdown-container {
  //$backColor: hsl(210, 100%, 70%);
  //$headColor: hsl(210, 100%, 50%);
  //$contColor: hsl(210, 100%, 90%);
  //$hovColor: hsl(210, 100%, 80%);
  
  /*
  html {
    background-color: $backColor;
    height: 100%;
    
    #reactApp * {
      box-sizing: border-box;
      border: 0px solid black;
      padding: 0px;
      margin: 0;
    }
  
    #reactApp {
      background-color: $backColor;
      height: 100%;
      padding: 0;
      padding-bottom: 20px;
  */
  
    //background-color: blue;
  height: 100%;
  width: 100%;
  padding: 80px 0 50px 0;
  margin: 0;
  background-color: $homeBackColor;

  #editorRow {
    //background-color: $backColor;
    padding: 0;
    margin: 15px 0;
    border: 0px solid red;
    border-radius: 5px;

    /*
    .empty {
      //background-color: $backColor;

    }*/

    #editorContainer {
      box-shadow: 0px 0px 10px;
      padding: 0px;
      margin: 0;
      //border-radius: 10px 10px 0 0;
    }

    #editorHead {
      background-color: $homeAccentColor;
      padding: 7px 10px;
      border: 0px solid black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border-radius: 10px 10px 0 0;
      
      #editHeadText {
        font-weight: bold;
        padding: 0px;
        margin: 0;
      }
      
      svg:hover {
        cursor: pointer;
        color: $homeOffWhite;
      }
      
    }

    #editor {
      background-color: $homeOffWhite;
      width: 100%;
      padding: 2px 5px;
      //border-bottom: 1px solid black;
      margin-bottom: -8px;
      font-family: monospace;
      font-size: .85em;
      border-radius: 0 0 0px 00px;
    }
    
    #editor:focus {
      outline: none;
    }
  }

  #previewRow {
    padding: 0 0 0 0px;
    background-color: $homeBackColor;
    margin: 0;
    //width: auto;
    
    /*
    .empty {
      //background-color: $backColor;
    }*/
    
    #previewContainer {
      box-shadow: 0px 0px 10px;
      padding: 0px;
      
      
      #previewHead {
        background-color: $homeAccentColor;
        padding: 7px 10px;
        //border: 1px solid black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        
        
        #prevHeadText {
          padding: 0px;
          margin: 0;
          font-weight: bold;
        }
        
        svg:hover {
          cursor: pointer;
          color: $homeOffWhite;
        }
        
      }

      #preview {
        background-color: $homeOffWhite;
        padding: 10px 15px;
        //border-bottom: 1px solid black;
        
        h1, h2 {
          font-weight: bold;
          padding: 15px 0 5px 0;
          border-bottom: 2px solid $homeBackColor;            
        }
        
        h2 {
          border-bottom: 1px solid $homeBackColor;
        }
        
        h3 {
          padding: 15px 0 0px 0;
          font-size: 1.5em;
        }
        
        code {
          background-color: white;
          padding: 2px;
          color: $homeBackColor;
          width: 10%;
        }
        
        
        
        pre {
          background-color: hsl(0, 0%, 100%);
          margin: 15px 0px 0px 0px;
          padding: 5px 5px 5px 5px;
        }
        
        p {
          margin: 15px 0px;
        }
        
        a {
          color: $homeAccentColor;
        }

        blockquote {
          margin-left: 20px;
          border-left: 3px solid $homeAccentColor;
          padding: 0px 3px;
          color: $homeAccentColor;
        }
        
        table {
          margin-bottom: 15px;
        }
        
        th, td {
          border: 2px solid $homeBackColor;
          padding: 2px 5px;
        }
        
        ul {
          padding-left: 40px;
          
        }
        
        ol {
          padding-left: 40px;
        }
        
        img {
          width: 100%;
          padding: 30px 30px 20px 30px;
        }
        
      }
      
    }
  }
}  

//random quote machine app styles
#quote-app-container {
  $univBordRad: 5px;
  $backColor: $homeAccentColor;
  $textAnimation: whiteOutIn 1s ease;

  background-color: $homeBackColor;
  //min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  //align-items: center;
  padding: 110px 0 40px 0;

  * {
    border-style: border-box;
    border: 0px solid black;
    padding: 0;
    margin: 0;

  }
  
  /*
  html {
    background-color: purple;
  }

  body {
    background-color: green;
    height: 100vh;
    display: flex;
    //for some reason codepen has an empty div that wouldn't go away without below. 
    //also, nesting to confirm SASS working
    //disappears when global padding=0
    *:nth-child(3) {
    display: none;
      }
    }

  #reactApp {
    background-color: blue;
    height: 100%;
    width: 100%;
  }

  .background {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }*/
  #quote-box-wrapper {
    //background-color: $darkAccentColor;
    padding: 20px;
    border-radius: 20px;
    height: auto;
  }

  #quote-box {
    background-color: white;
    width: 450px;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 0px solid $darkAccentColor;
  }

  #text {
    text-align: center;
    background-color: white;
    font-size: 32px;
    //animation: $textAnimation;
  }

  #author {
    background-color: white;
    text-align: right;
    font-size: 18px;
    margin-top: 20px;
    //animation: $textAnimation;
  }

  #allButtons {
    background-color: white;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  #socialButtons, #newQuoteArea {
    background-color: white;
    display: flex;
  }

  #allButtons button, a {
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: $univBordRad;
    text-decoration: none;
  }

  #allButtons button:hover, a:hover {
    color: white;
    cursor: pointer;
    filter: brightness(110%);
  }

  .twitterTumblr {
    margin-right: 10px;
  }

  @keyframes whiteOutIn {
    0% {
      
    }
    50% {
      color: white;
    }
    100% {
      
    }
  }

  @media (max-width: 550px) {
    #quote-box-wrapper {
      width: 95%;
    }

    #quote-box {
      width: auto;
    }
  }

  @media (max-width: 450px) {
    #allButtons {
      flex-direction: column;
      align-items: center;
    }

    #socialButtons {
      padding: 0 5px 20px 5px;
    }

    .twitterTumblr {
      margin: 0 5px 0 5px;
    }
  }

  @media (min-height: 700px) {
    padding: 170px 0 20px 0;
  }

  @media (min-height: 700px) {
    padding: 200px 0 20px 0;
  }
}

//drum app styles
#drum-container {
  $backColor: hsl(0, 0%, 70%);
  $contentColor: hsl(0, 0%, 50%);
  $appBackColor: hsl(0, 0%, 85%);
  
  background-color: $homeBackColor;
  padding: 0px;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    border: 0px solid black;
    box-sizing: border-box;
  }
  
  /*
  html {
    background-color: blue;
    padding: 0;
    height: 100vh; //can shrink to 60vh to look at devtools
  }
  
  body {
    background-color: red;
    height: 100%;
    margin: 0;
  }
  
  #root {
    background-color: green;
    height: 100%;
  }
  
  #app-container * {

    border: 0px solid black;
    padding: 0px;
  }
  
  #app-container {
    background-color: $backColor;
    padding: 0px;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  */
  #drum-machine {
    border: 0px solid orange;
    background-color: $darkAccentColor;
    margin: 0px;
    padding: 15px 20px 15px 15px;
    width: 600px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
  }
  
  #allDrumPads {
    width: 310px;
    padding: 0px;
    margin-left: 0px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
  
    .padRow {
      width: 310px;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
      
    }
    .drum-pad {
      background-color: $contentColor;
      border: 0px solid black;
      height: 70px;
      width: 90px;
      padding: 0px;
      border-radius: 7px;
      box-shadow: 3px 3px 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  
    .drum-pad:hover {
      cursor: pointer;
    }
  
    /*
    .drum-pad:active {
      //background-color: orange; this will be done with react i guess
    }*/
    
  }
  
  #ctrlDispContainer * {
    padding: 5px;
  }
  
  #ctrlDispContainer {
    width: 210px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
    .pwrBankCtrl {
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      p {
        padding: 0px 0px;
        margin-bottom: 1px;
        height: 18px;
        text-align: center;
        font-weight: bold;
        font-size: 0.9em;
      }
  
      .switchBack {
        background-color: black;
        padding: 3px;
        width: 50px;
        display: flex;
  
        .switch {
          background-color: $homeAccentColor;
          width: 20px;
          height: 15px;
        }
  
        .switch:hover {
          cursor: pointer;
        }
      }
      
    }
  
    #display {
      background-color: $contentColor;
      height: 50px;
      width:160px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      #dispText {
        margin: 0;
        font-weight: bold;
      }
    }
  
    #volumeCtrl {
      height: 5px;
      width: 100%;
      padding: 0;
      margin: 15px 0 0 0;
      //everything below subject to change to make slider
      
      //not 
      display: flex;
      justify-content: center;
      align-items: center;
  
      input[type='range'] {
        -webkit-appearance: none !important;
        appearance: none;
        border: 1px solid black;
        background: #404040;
        width: 100%;
        height: 5px;
        padding: 0px;
        box-shadow: 1px 1px 2px;
      }
  
      input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        background: $homeAccentColor;
        height: 25px;
        width: 10px;
      }
    } 
    
  }
  
  @media (max-width: 650px) {
    

    #drum-machine {
      flex-direction: column;
      justify-content: center;
      height: auto;
      width: auto;
    }
  }

  @media (max-height: 750px) {
    padding: 50px 0 0 0;
  }

  @media (max-height: 650px) {
    padding: 80px 0 0 0;
  }

  @media (max-height: 650px) {
    align-items: start;
    padding: 110px 0 50px 0;
    height: 100%;
  }
  
}

//bar chart styles
#bar-container {
  background-color: $homeBackColor;
  width: 100%;
  height: 100%;
  //border-radius: 5px;
  //box-shadow: 0px 0px 15px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  padding: 100px 0 20px 0;

  h1 {
    text-align: center;
    font-weight: default;
    padding: 0px;
    background-color: $darkAccentColor;
    color: white;
    margin: 0 0 0px 0;
    z-index: 0;
  }

  p {
    color: white;
    text-align: center;
  }
  
  #tooltip {
    opacity: 0;
    background-color: hsl(24, 100%, 4%);
    color: white;
    box-shadow: 0px 0px 15px;
    text-align: center;
    //width: auto;
    position: absolute;
    //top: 300px;
    padding: 10px;
    //margin: 10px;
  }

  #bar-chart-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding: 10px 40px;
    background-color: $darkAccentColor;
    //margin-top: -20px;
    max-width: 90%;
    //max-height: 90%;
    
  }

  #bar-chart {
    overflow: auto;
  }
  
  #bar-chart-svg {
    margin: 0;
    padding: 0;
    border: 0px solid black;
    background-color: $darkAccentColor;
    overflow: scroll;
    //width: auto;

    rect {
      fill: $homeAccentColor;
    }

    .ylabel {
      fill: white;
    }

    g {
      color: white;
    }
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 0.8;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }

  @media(min-height: 700px){
    padding: 150px 0 0 0;
  }
}

#scatter-container {
  height: 100%;
  width: 100vw;
  //background-color: white;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  border: 0px solid black;
  padding: 100px 0 50px 0;
  margin: 0px 0 0 0;
  background-color: $homeBackColor;

  * {
    border: 0px solid black;
    text-align: center;
    color: white;
  }

  h1 {
    text-align: center;
    background-color: $darkAccentColor;
    margin: 0;
    padding: 0px 0 0 0;
  }

  h2 {
    text-align: center;
    background-color: $darkAccentColor;
    margin: 0;
    margin: 10px 0 0 0;
    font-size: 1.2em;
  }

  #scatter-plot {
    max-width: 90%;
    padding: 20px;
    height: auto;
    background-color: $darkAccentColor;
    border-radius: 20px;
  }

  #scatter-svg-cont {
    overflow: auto;
  }

  #scatter-svg {
    border: 0px solid black;
    padding: 0 0 0px 0px;
    margin: 0 0px 0 0px;
    background-color: $darkAccentColor;
    overflow: scroll;
  }

  #tooltip {
    border: 0px solid black;
    border-radius: 5px;
    position: fixed;
    opacity: 0;
    background-color: $homeBackColor;
    padding: 10px;
    text-align: left;
    font-size: 0.8em;
    border: 1px solid $homeAccentColor;
  }

  @media (min-height: 750px) {
    padding: 150px 0 50px 0;
  }
}

//heatmap styles
#heatmap-app {
  min-height: 100%;
  height: auto;
  width: 100%;
  background-color: $homeBackColor;
  display: flex;
  justify-content: center;
  //align-items: center;
  padding: 100px 0 50px 0;
  color: white;
  //font-weight: 100;

  #heatmap-chart {
    background-color: $darkAccentColor;
    border: 0px solid red;
    height: auto;
    max-width: 90%;
    padding: 20px;
    border-radius: 20px;
    
    h1 {
      text-align: center;
    }

    h2 {
      text-align: center;
      font-size: 1.1em;
    }

    #heatmap-container {
      overflow: auto;
    }

    svg {
      //height: 700px;
      overflow: scroll;
      border: 0px solid purple;
    }
  }

 
  /*
  svg .bar:hover {
    z-index: 2;
    stroke-width: 1;
    color: black;
  }*/
  
  #tooltip {
    padding: 5px;
    background-color: black;
    color: white;
    text-align: center;
    position: absolute;
    left: -100px;
    top: 0px;
    visibility: hidden;
    border-radius: 5px;
    opacity: 0.9;
    
  }
  
  .legendRects {
    width: 34px;
    height: 25px;
    outline: 1px solid black;
    //stroke-width: 1px;
    //stroke: black;
  }

  @media (min-height: 700px) {
    padding: 120px 0 0 0;
  }
}

//choropleth styles
#choropleth-app {
  padding: 100px 0 50px 0;
  background-color: $homeBackColor;
  display: flex;
  //align-items: center;
  justify-content: center;
  min-height: 100%;

  #choropleth-map {
    padding: 20px;
    border: 0px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    background-color: $darkAccentColor;
    max-width: 90%;
    border-radius: 20px;
  }

  #title {
    text-align: center;
    font-size: 3em;
    color: white;
  }
  
  .subTitle {
    text-align: center;
    margin: 0 0 5px 0; 
    padding: 0;
    color: white;
  }
  
  #choro-map-cont {
    overflow: auto;
    height: auto;
  }
  
  #choropleth-map svg {
    border: 0px solid black;
    padding: 0 0 0 25px;
    overflow: scroll;
  }
  
  .county {
    stroke: white;
    stroke-width: 0.1px;
  }
  
  .state {
    stroke: white;
    stroke-width: 1.5px;
    opacity: 0.4;
    fill: none;
  }
  
  #legendAxis {
    stroke-width: 0px;
    
  }
  
  .legendRects {
    width: 36px;
    height: 10px;
  }
  
  #tooltip {
    padding: 10px;
    background-color: $homeAccentColor;
    color: white;
    text-align: center;
    position: absolute;
    left: -100px;
    top: 0px;
    visibility: hidden;
    border-radius: 2px;
    opacity: 0.8;
  }
}

//early work app (html/css pages)
#early-work-container {
  padding: 95px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $homeBackColor;
  min-height: 100vh;
  forced-color-adjust: none;

  #early-work-app {
    background-color: $darkAccentColor;
    width: 75vw;
    //max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 5px 20px;
    border-radius: 20px;

    p {
      font-size: 1.2em;
      color: white;
      padding: 15px 0 0 0;
      text-align: center;
      
      .link-text {
        text-decoration: underline
      }

      .link-text:hover {
        cursor: pointer;
      }
    }
  }

  #trombones-app {
    margin: 0 10px 10px 10px;
    //padding: 10px;
    width: 100%;
    background-color: #eee;
    font-family: sans-serif;

    #header {
      //position: fixed;
      //top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      width: auto;
      padding: 0;
      margin: 0; 
      background-color: #eee;
      
    }
    
    #header-img {
      height: 35px;
      padding: 0 0 0 15px;
    }
    
    #nav-bar {
      display: block;
      align-items: center;
      height: 100%;
      width: 60%;
      border: 0px solid red;
    }
    
    #nav-bar ul {
      list-style: none;
      display: flex;
      justify-content: end;
      align-items: center;
      height: 100%;
      padding: 0;
      margin: 0;
    }
    
    #nav-bar li {
      display: flex;
      align-items: center;
      padding: 0 20px 0 0;
      height: 20px;
    }
    
    #nav-bar a {
      text-decoration: none;
      text-align: center;
      color: black;
      
    }

    #nav-bar a:hover {
      text-decoration: underline;
    }
    
    #get-started {
      margin-top: 0px;
    }
    
    #email-header {
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }
    
    #form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    #email {
      width: 250px;
      height: 30px;
      padding-left: 5px;
      border-radius: 3px;
      border: 1px solid;
    }
    
    #submit {
      padding: 5px;
      margin: 12px 0 30px 0;
      background-color: #f1c40f;
      font-weight: 900;
      font-size: 1em;
    }
    
    .logod-divs {
      padding: 10px 20px;
      display: flex;
      align-items: center;
    }
    
    .features-img {
     display: block;
     width: 20vw;
     height: 125px;
     text-align: center;
    }

    #features svg {
      height: 70px;
      padding: 10px 20px;
      width: 60px;
    }
    
    #features .features-detail {
      display: flex;
      flex-direction: column;
      //width: 80vw;
      justify-content: center;
    }
    
    #features h2, #features p {
      margin: 0;
      padding: 2px 0;
      color: black;
      text-align: left;
    }
    
    #how-it-works {
      display: flex;
      justify-content: center;
    }
    
    #video {
      width: 100%;
      max-width: 560px;
      margin: 30px 0;

      
    }
    
    #pricing-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    
    #pricing {
      max-width: 1000px;
      width: 100%;
      display: flex;
      justify-content: space between;
    }
    
    .price-tier {
      width: calc(100%/3);
      text-align: center;
      margin: 10px;
      //height: 300px;
      display:flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid;
    }
    
    #pricing h3 {
      padding: 15px 0;
      width: 100%;
      margin: 0;
      background-color: #ddd;
      font-size: 1em;
    }
    
    #pricing h2 {
      margin: 0;
      padding: 15px 0;
    }
    
    #pricing p {
      margin: 0;
      padding: 5px 0;
      color: black;
      font-size: 1em;
    }
    
    .tier-select {
      margin-top: 20px;
      width: 35%;
      height: 40px;
      font-size: 1em;
      background-color: #f1c40f;
      border-radius: 2px;
      border: 0;
      margin: 10px 0 10px 0;
    }
    
    footer {
      background-color: #ddd;
      padding: 5px 10px;
      margin-top: 30px;
    }
    
    footer ul {
      list-style: none;
      display: flex;
      justify-content: right;
      padding-right: 5px;
      margin: 10px 0 0 0;
    }
    
    footer li {
      padding: 0 5px 0 15px;
      
    }
    
    footer span {
      text-decoration: none;
      color: black;
      font-size: 0.9em;
      
    }
    
    footer p {
      text-align: right;
      margin-top: 0px;
      font-size: 0.8em;
      color: #444;
      padding: 0;
    }
    
    @media (max-width: 900px) {
      #pricing {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      .price-tier {
        width: 300px;
      }
    }
    
    @media (max-width: 700px) {
      #header {
        justify-content: space-between;
      }

      #nav-bar {
        height: auto;
        width: auto;
      }

      #nav-bar ul {
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 125px;
        
        li {
          padding: 0;
        }
      }
    }
    
    @media (max-width: 600px) {
      #header {
        flex-direction: column;
        height: auto;

        img {
          padding: 0;
          width: 100%;
          height: auto;
        }

        ul {
          flex-direction: row;
          width: auto;
          padding: 0 0 15px 0;

          li {
            padding: 0 15px;
            text-decoration: underline;
          }
        }
      }
    
      #nav-bar {
        padding: 0;
      }
    }
    
    @media (max-width: 550px) {
      svg {
        display: none;
      }
    
      #features .features-detail {
        width: 100vw;
      }

      #features .features-detail h2 {
        text-align: center;
      }

      #features .features-detail p {
        text-align: center;
      }
      
      #get-started {
        padding: 0 10px;
      }

      .price-tier {
        width: 90%;
      }

      #email {
        width: auto;
      }
    }

    @media (max-width: 450px) {
      #nav-bar ul {
        flex-direction: column;
        padding: 0 0 5px 0;

      li {
        padding: 5px 0 0 0;
        height: auto;
      }
     }
    
    }
  }

  #js-doc-page {
    padding: 0 0 15px 0;
    width: 100%;

    * {
      border: 0px solid red;
    }

    #navbar {
      border-right: solid;
      border-color: rgba(0, 22, 22, 0.3);
      width: 300px;
      position: absolute;
      height: 590px;
      background-color: white;
      overflow: auto;
      z-index: 1;
    }
    
    #navHead {
      padding: 10px 0;
      text-align: center;
      font-size: 1.8em;
      border-bottom: 1px solid;
      color: black;
      margin: 0;
    }
    
    #navbar ul {
      margin: 0;
      padding: 0;
      //overflow-y: auto;
      overflow-x: hidden;
    }
    
    #navbar li {
      list-style: none;
      border-bottom: 1px solid;
      padding: 10px 0px 10px 25px;
      color: #4d4e53;
      
    }
    
    #navbar a {
      color: #4d4e53;
      text-decoration: none;
      cursor: pointer;
    }
    
    #main-doc {
      //position: absolute;
      margin-left: 300px;
      background-color: white;
      height: 590px;
      overflow: auto;
      //width: 400px;
      padding: 30px 30px 0 30px;
    }
    
    .mainHead {
      font-size: 1.7em;
      color: black;
    }

    p {
      text-align: left;
    }
    
    p, #main-doc ul, #main-doc li {
      margin: 0 0 15px 0;
      color: black;
    }
    
    code {
      background-color: #f7f7f7;
      display: block;
      margin: 0px 30px 10px 30px;
      padding: 20px 15px ;
      text-align: left;
      white-space: pre-line;
      word-break: normal;
      word-wrap: normal;
      color: #404040;
    }
    
    
  }

  //for js-doc-page
  @media (max-width: 1000px) {
    #js-doc-page {
      display: flex;
      flex-direction: column;
    

      #navbar {
        position: static;
        width: 100%;
        //max-width: 500px;
        height: 275px;
        z-index: 0;
        padding: 0;
        border-bottom: 2px solid;
      }
      
      #navHead {
        //display: block;
        //padding: 0 auto;
        margin: 0;
        position: static;
        width: 100%;
        //max-width: 100%;
        text-align: center;
        z-index: 1;
        background-color: white;
        border-bottom: 2px solid black;
      }
    
      #navbar ul {
        //display: block;
        max-height: 216px;
        //overflow-x: hidden;
        margin: 0px 0 0 0;
      }
    
      #navbar li:last-of-type {
        border-bottom: 0;
      }
    
      #navbar header {
        border-bottom: 2px solid;
      }

      #main-doc {
        position: static;
        margin: 0px 0 0 0;
      }
    }
  }

  @media (max-width: 975px) {
    p {
      display: flex;
      flex-direction: column;
      //justify-content: center;
    }

    .hide-narrow {
      display: none;
    }
  }

  @media (max-width: 700px) {
    p {
      //flex-direction: column;
      justify-content: center;
    }

  }

  @media (max-width: 650px){
    #early-work-app {
      width: 82vw;
    }
  }

  @media (max-width: 450px){
    #early-work-app {
      width: 90vw;
    }
  }

  #tribute-page {
    background-color: #f9f2ec;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
      text-align: center;
      padding: 20px 10px 0 10px;
      margin: 0;
    }

    p {
      color: black;
      margin: 10px 0 20px 0;
      padding: 0 10px;
    }

    #sub-title {
      text-align: center;
    }

    #img-div {
      background-color: white;
      padding: 5px 10px;
      text-align: center;
      margin: 0 20px 0 20px;
      //height: auto;
      //width: auto;
    }

    img {
      max-width: 100%;
      //height: auto;
      //width: auto;
      display: block;
      margin: 5px 0px 0 0;
      text-align: center;
    }

    #img-caption p {
      margin-bottom: 10px;
      font-size: 0.95em;
    }

    #tribute-info {
      width: 500px;
      max-width: 100%;
      margin: auto;
    }

    h3 {
      text-align: center;
      margin: 40px 0;
      font-size: 1.4em;
      padding: 0 10px;
    }

    #tribute-info li {
      padding: 0 25px 15px 0px;
      line-height: 1.5;
    }

    blockquote {
      max-width: 100%;
      width: 450px;
      margin: auto;
      padding: 0 30px 0 30px;
    }

    blockquote p {
      text-align: left;
    }

    blockquote #full-cite {
      display: flex;
      width: auto;
      padding: 0 0 0 20px;

      #cite-dashes {
        padding: 0 0px 0 0;
        margin: 0 0 0 0;
        min-width: 15px;
        height: 25px;
      }

      #cite-name {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
      }
      
    }

    #wikilink {
      padding: 10px 10px 5px 10px;
      margin:  10px 20px 20px 20px;
      max-width: 600px;
    }
  }

  #survey-form {
    h1, p {
      margin: auto;
      text-align: center;
      color: white;
    }
    
    h1 {
      padding-top: 0 0 0 0;
    }
    
    form {
      width: 60vw;
      max-width: 500px;
      min-width: 300px;
      margin: 20px auto;
      padding: 10px;
      border: 1px solid white;
      color: white;
    }
    
    label {
      display: block;
    }
    
    input, select, textarea {
      margin: 2px 0 10px 0;
      width: 100%;
      min-height: 2em;
    }
    
    .inline {
      width: unset;
      margin: 0 0.5em 0 0;
      vertical-align: middle;
    }
  }
}

//loading component styles
#loadingContainer {
  padding: 0px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  border: 0px solid white;
  animation: loadload 0.1s ease-in;
  animation-iteration-count: 1;

  * {
    border: 0px solid white;
  }
  
  img {
    height: 200px;
    width: 200px;
    animation: starpower 10s linear infinite;
  }

  h1 {
    color: white;
    margin: 20px 0 0 0;

    #load-dot-one {
      animation: anim-dot-one 3s step-start infinite;
    }

    #load-dot-two {
      animation: anim-dot-two 3s step-start infinite;
    }

    #load-dot-three {
      animation: anim-dot-three 3s step-start infinite;
    }
    
  }

  @media (max-height: 400px) {
    padding: 80px 0 0 0;
  }

  @media (max-height: 500px) {
    padding: 0px 0 0 0;
  }

  @keyframes loadload {
    0% {
      visibility: hidden
    }

    50% {
      visibility: visible
    }

    100% {
      visibility: visible
    }

  }

  @keyframes starpower {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anim-dot-one {
    0% {
      color: hsla(0, 100%, 100%, 0)
    }

    25% {
      color: hsla(0, 100%, 100%, 0)
    }

    50% {
      color: hsla(0, 100%, 100%, 1)
    }

    75% {
      color: hsla(0, 100%, 100%, 1)
    }

    100% {
      color: hsla(0, 100%, 100%, 1)
    }
  }

  @keyframes anim-dot-two {
    0% {
      color: hsla(0, 100%, 100%, 0)
    }

    25% {
      color: hsla(0, 100%, 100%, 0)
    }

    50% {
      color: hsla(0, 100%, 100%, 0)
    }

    75% {
      color: hsla(0, 100%, 100%, 1)
    }

    100% {
      color: hsla(0, 100%, 100%, 1)
    }
  }

  @keyframes anim-dot-three {
    0% {
      color: hsla(0, 100%, 100%, 0)
    }

    25% {
      color: hsla(0, 100%, 100%, 0)
    }

    50% {
      color: hsla(0, 100%, 100%, 0)
    }

    75% {
      color: hsla(0, 100%, 100%, 0)
    }

    100% {
      color: hsla(0, 100%, 100%, 1)
    }
  }
  
}